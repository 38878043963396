import React from 'react';
import Nav from '../nav';
import Footer from '../footer';
import './article.css'
import image1 from '../images/coin/1.jpg'
import image2 from '../images/coin/2.jpg'
import image3 from '../images/coin/3.jpg'
import image4 from '../images/coin/4.jpg'
import image5 from '../images/coin/5.jpg'
import image6 from '../images/coin/6.jpg'


export default function article2() {
    return (
        <>
            <Nav />
            <div className="article-container row my-5">
                <div className="image-container col-10 col-md-6 my-4">
                    <div className="container col-md-8">
                        <img src={image1} alt="" className="img-fluid" />
                        <img src={image2} alt="" className="img-fluid" />
                        <img src={image3} alt="" className="img-fluid" />
                        <img src={image4} alt="" className="img-fluid" />
                        <img src={image5} alt="" className="img-fluid" />
                        <img src={image6} alt="" className="img-fluid" />


                    </div>
                </div>
                <div className="text-container col-10 col-md-6 col-sm-4 ">
                    <div className="sticky-text">
                        <h1 className="display-5">COIN SENSEI</h1>
                        <h6 className="fw-bold my-2">WEBSITE</h6>
                        <p className="col-md-9">At Coin Sensei, we are a pioneering force in the world of cryptocurrency, offering a comprehensive platform that seamlessly combines P2P trading and advanced signal services.

                            Our mission is to empower users with a secure and intuitive environment for buying and selling digital assets directly. With a team of seasoned experts, we provide real-time crypto signals that guide traders towards informed decisions, optimizing their investment strategies.

                            Backed by cutting-edge technology, our project is committed to revolutionizing the way individuals navigate the dynamic landscape of cryptocurrency trading.

                        </p>


                        <ul className="my-5">
                            <li>Crypto</li>
                            <li>Buy & Sell</li>
                            <li>Packages</li>
                            <li>Contact Us</li>
                        </ul>

                        <ul className="my-5">
                            <li><span>LANGUAGES : </span>REACT JS</li>
                            <li><span>YEAR:</span> 2023-PRESENT</li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

