import React from 'react'
import '../comp/form.css'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
export default function Form() {
  return (
    <>
<div className="container-contact">
        <div className="row-contact mx-5">
          <div className="col-md-5">
            <div className="section-title">
              <h2>Think we're a good fit? Get in touch</h2>
            </div>
            <div className="contact-info">
              <div className="contact ">
                <div className="col-md-6">
                  <a href="https://www.instagram.com/loftylogix/" target="_blank" rel="noopener noreferrer">Linkedin</a>
                </div>
                <div className="col-md-6">
                  <a href="mailto:loftylogix@.com">loftylogix@.com</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="contact-form">
              <form>
                <div class="form-item">
                  <label for="name"><span class="entypo-user"></span></label><input name="name" type="text" placeholder="Name" required autofocus></input>
                </div>
                <div class="form-item">
                  <label for="email"><span class="entypo-mail"></span></label><input name="email" type="email" placeholder="Email" required></input>
                </div>
                <div class="form-item">
                  <label for="phone"><span class="entypo-mobile"></span></label><input name="Message" type="tel" placeholder="Message" style={{ marginTop: "0px" }} required></input>
                </div>

                <Link to="/">
                  <div class="button"><p class="btn-title">Send Message <FontAwesomeIcon className='' icon={faArrowRight} beatFade size="xl" style={{ color: "#000000", }} /></p></div>
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>

    <div class="marquee " >
  <div class="track">
    <div class="content">&nbsp;Content is king · Iconic brands demand attention · Speak to your audience in their language about what’s in their heart · The storyteller sets the vision, values, and agenda of an entire generation that is to come · Don't use social media to impress people; use it to impact people · Our head of social media is the customer ·</div>
  </div>
</div>

  </>
  )
}
