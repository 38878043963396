import React from 'react'
import "./nav.css"
import { Link } from 'react-router-dom'
export default function Nav() {
  return (
          <>
      <div className="header">
    <header className="d-flex flex-wrap justify-content-center py-4 mb-3 border-bottom">
      <Link to="/" className="d-flex  align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
        
        <h1 className="fs-2 head mx-4">LOFTY LOGIX</h1>
      </Link>

      <ul className="nav nav-pills text-dark">
        <li className="nav-item mx-4"><Link to="/about" className="nav-link " aria-current="page">About</Link></li>
        <li clasNames="nav-item dropdown">
          <Link className="nav-link dropdown-toggle" to="/portfolio" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Portfolio
          </Link>
          <ul class="dropdown-menu mt-3 p-4 bg-dark text-white" aria-labelledby="navbarDropdown">
            <li><Link className="dropdown-item p-2 bg-dark text-white text-decoration-underline" to="/social">Projetcs</Link></li>
            <li><Link className="dropdown-item p-2 bg-dark text-white text-decoration-underline" to="/content">Content Writing</Link></li>
          </ul>
        </li>
        <li className="nav-item  mx-4"><Link to="/contact" className="nav-link">Contact</Link></li>
    
      </ul>
    </header>
  </div>
 
    </>
  )
}
