
import Home from './Home';
import About from './comp/about';
import Contact from './comp/contact';
import Content from './comp/content';
import Social from './comp/social';
import Article from './Article/article';
import Article2 from './Article/article2';
import Article3 from './Article/article3';
import Article4 from './Article/article4';
import Article5 from './Article/article5';
import Article6 from './Article/article6';
import Article7 from './Article/article7';
import Article8 from './Article/article8';
import {Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
    <Routes>
        <Route  path="/" element={<Home  />} />
        <Route  path="/about" element={<About  />} />
        <Route path="/contact" element={<Contact  />} />
        <Route path="/content" element={<Content  />} />
        <Route path="/social" element={<Social  />} />
        <Route path="/article" element={<Article  />} />
        <Route path="/article2" element={<Article2  />} />
        <Route path="/article3" element={<Article3  />} />
        <Route path="/article4" element={<Article4  />} />
        <Route path="/article5" element={<Article5  />} />
        <Route path="/article6" element={<Article6  />} />
        <Route path="/article7" element={<Article7  />} />
        <Route path="/article8" element={<Article8  />} />
      </Routes>
   
    </>
  );
}

export default App;
