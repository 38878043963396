import React from "react";
import Nav from "../nav";
import Footer from "../footer";
import "./article.css";
import apple3 from "../images/apple (3).jpg";
import apple4 from "../images/apple (4).jpg";
import apple2 from "../images/apple (2).jpg";
import apple1 from "../images/apple (1).jpg";

export default function Article5() {
  return (
    <>
      <Nav />
      <div className="article-container row my-5">
        <div className="image-container col-10 col-md-6 my-4">
          <div className="container col-md-8">
            <img src={apple3} alt="" className="img-fluid" />
            <img src={apple4} alt="" className="img-fluid" />
            <img src={apple2} alt="" className="img-fluid" />
            <img src={apple1} alt="" className="img-fluid" />
          </div>
        </div>
        <div className="text-container col-10 col-md-6 col-sm-4 ">
          <div className="sticky-text">
            <h1 className="display-5">B.K Clothing's </h1>
            <h6 className="fw-bold my-2">Featured products</h6>
            <p className="col-md-9">
              Dress Responsibly, Look Fabulous" Shop all.
            </p>

            <ul className="my-5">
              <li> B.K Clothing's</li>
              <li>Development</li>
              <li>products</li>
              <li>Desings</li>
            </ul>

            <ul className="my-5">
              <li>
                <span>YEAR:</span> 2023
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
