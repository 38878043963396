import React from 'react';
import Nav from '../nav';
import Footer from '../footer';
import './article.css'
import shop5 from "../images/shop (1).webp"
import shop3 from "../images/shop (2).jpg"
import shop4 from "../images/shop (3).jpg"
import shop2 from "../images/shop (4).jpg"
import shop1 from "../images/shop (6).jpg"

export default function Article6() {
  return (
    <>
      <Nav />
      <div className="article-container row my-5">
        <div className="image-container col-10 col-md-6 my-4">
          <div className="container col-md-8">
            <img src={shop5} alt="" className="img-fluid" />
            <img src={shop3} alt="" className="img-fluid" />
            <img src={shop4} alt="" className="img-fluid" />
            <img src={shop2} alt="" className="img-fluid" />
            <img src={shop1} alt="" className="img-fluid" />
  

          
          

          </div>
        </div>
        <div className="text-container col-10 col-md-6 col-sm-4 ">
          <div className="sticky-text">
            <h1 className="display-5">AppleHub.PK </h1>
            <h6 className="fw-bold my-2">Featured Outlet</h6>
            <p className="col-md-9">Apple Shop, one of the fastest-developing Stores in Pakistan, is a trustworthy platform that assists our clients with high-quality products. Online shopping from a tremendous variety of all Apple products at the most affordable price rate. We ship apple products all over Pakistan including iPads, iPhones, iPad & iPhone accessories, Macbook Pro, Mac Air, Audio Video accessories.
.</p>
            <p className="col-md-9">
Our customer service team, well-trained and qualified, will assist customers at any time, before the sale, in the procedure of the sale, and after-sale. You can utilize different channels to be in contact with us..</p>
             

            <ul className="my-5">
              <li>Karachi Outlet</li>
              <li>Lahore Outlet</li>
              <li>products</li>
              <li>Desings</li>
            </ul>

            <ul className="my-5">
              <li><span>YEAR:</span> @2023</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
