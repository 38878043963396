import React from 'react'
import Nav from './nav'
import Home1 from './comp/home1'
export default function Home() {
  return (
          <>
<Nav/>
<Home1/>
  </>
  )
}
