import React from 'react';
import backgroundImage from '../images/teamcover.jpg';

const MyComponent = () => {
  const divStyle = {
    backgroundImage: `url(${backgroundImage})`,
    height: '100vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover', // Use 'cover' to make the image cover the entire container
  };
  
  return (
    <>
      <section className="bg-image img-fluid" style={divStyle}></section>
      <div className='container p-3 p-md-5 m-md-3'>
        <div className="container-fluid p-3 p-md-5">
          <div className="row justify-content-center">
            <div className="col-md-8 p-3 p-md-2">
              <div className="section-title p-2">
                <h5>My Story</h5>
                <p className="mb-4">
                  Welcome to Lofty Logix Software House, where innovation meets excellence, and creativity is the driving force behind every project we undertake. We are more than just a software development company; we are your trusted partner in achieving digital success. In this era of rapid technological advancement, we understand the importance of staying ahead of the curve, and that's why we offer a comprehensive suite of services that encompass web development, machine learning, social media marketing, and graphic design.
                </p>
                <p>
                  At Lofty Logix Software House, our commitment to excellence is unwavering. We take pride in our ability to adapt to the ever-evolving digital landscape and deliver solutions that exceed expectations. We are not just a service provider; we are a partner invested in your success. With us by your side, you can reach new heights in the digital realm, ensuring your brand remains relevant, competitive, and memorable.
                </p>
                <p>
                  So, whether you're embarking on a web development journey, exploring the potential of machine learning, aiming to amplify your social media presence, or in need of captivating graphic design, trust Lofty Logix Software House to deliver outstanding results. Elevate your digital presence with us, and together, let's reach for the digital skies.
                </p>
              </div>
            </div>
            <div className="col-md-4 p-3 p-md-2">
              <div className="section-title p-4">
                <h5>Contact</h5>
              </div>
              <div className="text-editor mx-4">
                <a href="mailto:sylvia@sylviaogweng.com" className='text-dark'>loftylogix@gmail.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className='container-fluid p-3 p-md-5'>
        <h2 className="display-6 text-center mb-5 p-4 font-weight-bold">SQUAD MEMBERS AND LEADERS</h2>
        <div className="table-responsive p-3">
          <table className="table text-center text-dark">
            <thead className='text-dark border-success'>
              <tr>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th>Dawood Ahmad Sandhu</th>
                <th>Muhammad Salman Haider</th>
                <th>Hasnain Shareef</th>
                
              </tr>
              <tr>
                <th>Abdullah Mujahid</th>
                <th>Muhammad Hasnain</th>
                <th>Raveem Sohail</th>
              </tr>
              <tr>
                <th>Zuhad Ul Hadi</th>
                <th>Masab Ali</th>
                <th>Sanan Ali</th>
                
                
              </tr>
              <tr>
                <th>Husnain Bukhari</th>
                <th>Mahmood Ahmad</th>
                <th>Bilal Afzaal</th>
              </tr>
            </thead>
          </table>
        </div>
      </section>
    </>
  );
};

export default MyComponent;
