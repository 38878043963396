import React from 'react'
import Nav from '../nav'
import Footer from '../footer'
import Image from './image'
export default function About() {
  return (
          <>
<Nav/>
<Image/>
<Footer/>


  </>
  )
}
