import React from 'react'
import Nav from '../nav'
import Form from './form'
import Footer from '../footer'


export default function Contact() {
  return (
    <>
      <Nav />
      <Form />
      <Footer />


    </>
  )
}
