import React from 'react'
import Nav from '../nav'
import Footer from '../footer'
import ContW from '../comp/ContW'

export default function Content() {
  return (
          <>
<Nav/>
<ContW/>
<Footer/>


  </>
  )
}
