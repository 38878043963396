import React from 'react'
import Nav from '../nav'
import Footer from '../footer'
import Pic from '../pic'
export default function Social() {

  return (
          <>
<Nav/>
<Pic/>
<Footer/>


  </>
  )
}
