import React from 'react';
import Nav from '../nav';
import Footer from '../footer';
import './article.css'
import image1 from'../images/lofty/1.jpg'
import image2 from'../images/lofty/2.jpg'
import image3 from'../images/lofty/3.jpg'
import image4 from'../images/lofty/4.jpg'
import image5 from'../images/lofty/5.jpg'


export default function Article() {
  return (
    <>
      <Nav />
      <div className="article-container row my-5">
        <div className="image-container col-10 col-md-6 my-4">
          <div className="container col-md-8">
            <img src={image1} alt="" className="img-fluid" />
            <img src={image5} alt="" className="img-fluid" />
            <img src={image3} alt="" className="img-fluid" />
            <img src={image4} alt="" className="img-fluid" />
            <img src={image2} alt="" className="img-fluid" />

          </div>
        </div>
        <div className="text-container col-10 col-md-6 col-sm-4 ">
          <div className="sticky-text">
            <h1 className="display-5" style={{letterSpacing:'-10'}}>LOFTY LOGIX</h1>
            <h6 className="fw-bold my-2">WEBSITE</h6>
            <p className="col-md-9">Lofty Logix Software House is a cutting-edge tech company known for its innovative software solutions. 
            With a talented team of developers, they specialize in creating custom software applications that drive efficiency and productivity. 
            Their commitment to quality and client satisfaction sets them apart in the software industry. Experience the future of software
             development with Lofty Logix.</p>
            

            <ul className="my-5">
              <li>Machine Learning</li>
              <li>Web Development</li>
              <li>Social Media Marketing</li>
              <li>SEO</li>
            </ul>

            <ul className="my-5">
              <li><span>LANGUAGES : </span>HTML, CSS , JAVASCRIPT</li>
              <li><span>YEAR:</span> 2023-PRESENT</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
