import React from 'react'
import "./pic.css"
import img1 from './images/pic 1.jpg'
import img2 from './images/pic 2.jpg'
import img3 from "./images/pic 3.jpg"
import img4 from "./images/pic 4.jpg"
import img5 from "./images/pic 5.jpg"
import img6 from "./images/apple.jpg"
import img7 from "./images/pic 7.jpg"
import img8 from "./images/pic 8.jpg"
import { Link } from 'react-router-dom'
export default function Pic() {
  return (
          <>
<section >
    <div className="container-fluid ">
        <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12 portfolio-item">
                <div className="card">
                   <Link to="/article"> <img src={img1} className="card-img-top" alt="Dew IQ"/> </Link>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 portfolio-item">
                <div className="card">
                    <Link to="/article4"><img src={img4} className="card-img-top img-fluid" alt="Dew IQ"/> </Link>
                </div>
            </div>
         
            <div className="col-lg-4 col-md-6 col-sm-12 portfolio-item">
                <div className="card">
                    <Link to="/article8"><img src={img8} className="card-img-top w-100" alt="Edenrowe"/></Link>
                   
                </div>
            </div>
          
        </div>

    </div>
    <div className="container-fluid ">
        <div className="row justify-content-center">
        <div className="col-lg-4 col-md-6 col-sm-12 portfolio-item">
                <div className="card">
                    <Link to="/article7"><img src={img7} className="card-img-top" alt="Dew IQ"/></Link> 
                </div>
            </div>
        <div className="col-lg-4 col-md-6 col-sm-12 portfolio-item">
                <div className="card">
                  <Link to="/article2">  <img src={img2} className="card-img-top" alt="Edenrowe"/></Link>
                   
                </div>
            </div>
         
            <div className="col-lg-4 col-md-6 col-sm-12 portfolio-item">
                <div className="card">
                    <Link to="/article6"><img src={img6}  className="card-img-top" alt="Saul Good Gift Co"/></Link>
                </div>
            </div>
        </div>

    </div>
    <div className="container-fluid ">
        <div className="row justify-content-center">
          
            <div className="col-lg-4 col-md-6 col-sm-12 portfolio-item">
                <div className="card">
                   <Link to="/article3"> <img src={img3}  className="card-img-top" alt="Saul Good Gift Co"/></Link>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 portfolio-item">
                <div className="card">
                   <Link to="/article5"> <img src={img5} className="card-img-top" alt="Edenrowe"/>
</Link>                   
                </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 portfolio-item">
               
            </div>
        </div>

    </div>
</section>

      </>
  )
}
