import React from 'react'
import "./footer.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowRight } from '@fortawesome/free-solid-svg-icons'
export default function Footer() {
  return (
    
      <footer id="qodef-page-footer " >
    <div id="qodef-page-footer-top-area">
        <div id="qode" className="container">
            <div className="row">
                <div className="col-md-6">
                    <div id="block-13" className="widget widget_block" data-area="qodef-footer-top-area-column-1"></div>
                </div>
                <div className="col-md-6">
                    <div id="grete_core_separator-7" className="widget widget_grete_core_separator" data-area="qodef-footer-top-area-column-2">
                        <div className="qodef-shortcode qodef-m  qodef-separator clear">
                          <p classNameName='p1 '>Email 
                          <FontAwesomeIcon icon={faArrowRight}  className='mx-5' beatFade size="2xl" style={{color: "black",}} />                  
                           </p>
                             <hr />

                        </div>
                    </div>
                    <div id="block-23" className="widget widget_block" data-area="qodef-footer-top-area-column-2">
                        <div className="wp-block-contact-form-7-contact-form-selector">
                            <div className="wpcf7 js" id="wpcf7-f7330-o1" lang="en-US" dir="ltr">

                            </div>
                        </div>
                        <div id="block-35" className="widget widget_block" data-area="qodef-footer-top-area-column-2">
                            <p >*Sign up to receive a monthly summary of my favourite marketing links, articles, and tools.<br/> Coming soon to an inbox near you.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="qodef-page-footer-bottom-area">
        <div id="qodef-page-footer-bottom-area-inner" className="container">
            <div className="row">
                <div className="col-md-6">
                    <div id="block-31" className="widget widget_block" data-area="qodef-footer-bottom-area-column-1">
                        <div  aria-hidden="true" className="wp-block-spacer"></div>
                    </div>
                    <div id="grete_core_single_image-2" className="widget widget_grete_core_single_image" data-area="qodef-footer-bottom-area-column-1">
                        <div className="qodef-shortcode qodef-m  qodef-single-image qodef-layout--default  qodef--retina">
                          
                        </div>
                    </div>
                    <div id="block-14" className="widget widget_block widget_text mr-5" data-area="qodef-footer-bottom-area-column-1">
                        <p ></p>
                        <h3>LOFTY LOGIX</h3>
                        <p className="qodef-no-margin-bottom" >© 2023 LOFTY LOGIX. <br /> All Rights Reserved.</p>
                        <p></p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div id="block-15" className="widget widget_block" data-area="qodef-footer-bottom-area-column-2">
                        <div className="wp-block-columns is-layout-flex wp-container-12 wp-block-columns-is-layout-flex">
                            <div className="wp-block-column is-layout-flow wp-block-column-is-layout-flow">
                                <h5 className="wp-block-heading">Info</h5>
                                <p>Pakistan-Based.<br/>Working Worldwide.</p> <br/>
                                <p><a href="mailto:loftylogix@gmail.com" data-type="mailto" data-id="mailto:loftylogix@gmail.com" target="_blank" rel="noreferrer noopener">loftylogix@gmail.com</a></p>
                            </div>
                            <div className="wp-block-column is-layout-flow wp-block-column-is-layout-flow">
                                <h5 className="wp-block-heading">Links</h5>
                                <p><a  href="https://www.facebook.com/id=100095191020463" target="">Instagram</a></p>
                                <p><a href="https://www.instagram.com/loftylogix/" target="">LinkedIn</a></p>
                            </div>
                            <div className="wp-block-column is-layout-flow wp-block-column-is-layout-flow"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
    
  )
}
